import request from '@/utils/request'

/*
name:销售名字
*/
export function queryManger(data) {
  return request({
    url: '/portal/crm/manager/search',
    method: 'get',
    params: data
  })
}

/*
添加销售

*/
export function addManger(data) {
  return request({
    url: '/portal/crm/manager/add',
    method: 'post',
    data,
  })
}

/*
添加销售

*/
export function editManger(data) {
  return request({
    url: '/portal/crm/manager/edit',
    method: 'post',
    data,
  })
}

/*
分配客户经理
id：客户id
managerId：客户经理id
*/
export function allotManger(data) {
  return request({
    url: '/portal/crm/srPortalUser',
    method: 'put',
    data,
  })
}


/*
居间人上传logo
*/
export function uploadLogo(data) {
  return request({
    url: '/portal/crm/manager/logo',
    method: 'post',
    data,
  })
}

export function deleteManger(data) {
  return request({
    url: `/portal/crm/srPortalManager/${data.id}`,
    method: 'delete',
  })
}
