<template>
  <el-dialog
    append-to-body
    :visible="dialogFlag"
    :before-close="handleClose"
    width="300px"
    class="allocation-manage"
    center
  >
    <p class="title">{{getText()}}客户经理</p>
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
    >
      <el-form-item prop="name">
        <div class="search-box display-flex justify-center">
          <el-autocomplete
          class="el-autocomplete-box"
          v-model="form.name"
          :fetch-suggestions="querySearchAsync"
          placeholder="请输入内容"
        >
          <template slot-scope="{ item }">
            <div>{{ item.value=item.name }}</div>
          </template>
        </el-autocomplete>
        </div>
      </el-form-item>
    </el-form>
    <div class="display-flex justify-center">
      <el-button round size="small" @click="calcelHandle">取消</el-button>
      <el-button round type="primary" size="small" @click="submitHandle">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { allotManger, queryManger } from '@/api/alitatech/manager'
export default {
  props: {
    dialogFlag: {
      type: Boolean,
      required: false
    },
    manageName: {
      type: String,
    },
    clientId:{
      type: Number,
    },
  },
  data() {
    return {
      form:{
        name: ''
      },
      rules:{
        name: [
          { required: true, message: '请选择客户经理', trigger: 'blur' },
        ],
      },
      manageList: [],
    }
  },
  watch:{
    manageName(newValue){
      this.form.name = newValue;
    },
  },
  created(){
    this.getList();
  },
  methods: {
    getText(){
      return this.manageName ? '转移': '分配';
    },
    getList(){
      queryManger({
        name: '',
      }).then((res) => {
        this.manageList = res;
      });
    },
    close(){
      this.$emit('close');
      this.$refs.form.clearValidate();
    },
    handleClose(){
      this.close();
    },
    calcelHandle(){
      this.close();
    },
    getManager(name){
      let codeIndex = this.manageList.findIndex((item) => {
        return item.name == name;
      });
      if(codeIndex > -1){
        return this.manageList[codeIndex];
      }else {
        return {
          id: '',
          managerCode: '',
        }
      }
    },
    submitHandle(){
      this.$refs.form.validate((valid) => {
        if(valid){
          allotManger({
            id: this.clientId,
            managerId: this.getManager(this.form.name).id,
            managerCode: this.getManager(this.form.name).code
          }).then((res) => {
            this.$parent.init();
            this.$notify({
              title: '操作成功',
              type: 'success',
            });
            this.close();
          })
        }else {
          console.log('验证不通过')
        }
      })
    },
    querySearchAsync(queryString, cb) {
      queryManger({
        name: queryString,
      }).then((res) => {
        this.manageList = res;
      });

      this.timeout = setTimeout(() => {
        cb(this.manageList);
      }, 1000);
    },
  }
}
</script>
<style lang="less">
  .allocation-manage {
    .el-form-item__error {
      padding-left: 40px;
      text-align: center;
    }
  }
</style>
<style lang="less" scoped>
.allocation-manage {
  .title {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    color: #333333;
  }
  .el-autocomplete {
    margin: 0 auto;
  }
}
</style>
