<template>
  <el-dialog
    append-to-body
    :visible="dialogFlag"
    :before-close="handleClose"
    width="800px"
  >
    <blockquote class="my-blockquote">申请信息</blockquote>
    <el-tabs v-model="activeName">
      <el-tab-pane label="公司信息" name="first">
        <el-form
          ref="form"
          :model="form"
          label-width="80px"
          label-position="left"
        >
          <el-row>
            <el-col>
              <el-form-item
                label="公司名称"
              >
                <span>{{form.companyName}}</span>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item
                label="公司介绍"
              >
                <div class="has-height">{{form.companyIntro}}</div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="公司官网"
              >
                <div class="has-height">{{form.companyWebsite}}</div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="营业执照"
              >
                <img
                  class="img-ele"
                  @click="imgHandel(form.companyLisence)"
                  :src="form.companyLisence"
                  alt=""
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="联系地址"
              >
                <span>{{form.contactAddress}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="联系人姓名"
              >
                <span>{{form.contactName}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="联系人电话"
              >
                <span>{{form.contactPhone}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系人邮箱">
                <span>{{form.contactEmail}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="certificate-upload"
          >
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  class=""
                  label="联系人身份证照片"
                >
                  <img
                    class="img-ele"
                    @click="imgHandel(form.contactIdPic)"
                    :src="form.contactIdPic"
                    alt=""
                  >
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="联系人身份证照片"
                  class=""
                >
                  <img
                    class="img-ele"
                    @click="imgHandel(form.contactIdPicBack)"
                    :src="form.contactIdPicBack"
                    alt=""
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="背景信息" name="second">
        <el-form
          ref="form"
          :model="formBackground"
          label-width="auto"
          label-position="left"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="外汇/金融行业从业时间">
                <!--返回数据也要number-->
                <span v-if="formBackground.employmentTime == 1">0-2年</span>
                <span v-if="formBackground.employmentTime == 2">2-5年</span>
                <span v-if="formBackground.employmentTime == 3">5-10年</span>
                <span v-if="formBackground.employmentTime == 4">10年以上</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="曾经合作过的经纪商">
                <span>{{formBackground.coopBrokerUsed}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="isATeam" label="是否有团队（是，人数多少）">
                <span>{{formBackground.isATeam}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="最高峰名下客户量（人数）">
                <span>{{formBackground.custAmountMax}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="最高峰客户资金量">
                <span>{{formBackground.financingVolumeMax}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="现有客户量（人数）" >
                <span>{{formBackground.custAmountNow}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item  label="现有客户资金量（美元）">
                <span>{{formBackground.financingVolumeNow}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="现在的业务营销模式">
                <span>{{formBackground.bussMarketingMode}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="计划的社区营销模式">
                <span>{{formBackground.commMarketingMode}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="3个月预计客户量（人数）">
                <span>{{formBackground.custAmountExpect}}</span>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="3个月预计客户资金量（美元）">
            <span>{{formBackground.financingVolumeExpect}}</span>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
export default {
  props: {
    dialogFlag: {
      type: Boolean,
      required: false
    },
    form:{
      type: Object,
      default(){
        return {
          companyName: '',
          companyIntro: '',
          companyWebsite: '',
          companyLisence: '',
          contactAddress: '',
          contactName: '',
          contactPhone: '',
          contactEmail: '',

          contactIdPic: '',
          contactIdPicBack:'',
        }
      }
    },
    formBackground:{
      type: Object,
      default(){
        return {
          employmentTime: 1,
          coopBrokerUsed:'',
          isATeam: '',
          custAmountMax: '',
          financingVolumeMax: '',
          custAmountNow: '',
          financingVolumeNow: '',
          bussMarketingMode: '',
          commMarketingMode: '',
          custAmountExpect: '',
          financingVolumeExpect: '',
        }
      }
    },
  },
  data() {
    return {
      activeName: 'first',
    }
  },
  methods: {
    close(){
      this.$emit('close');
    },
    handleClose(){
      this.close();
    },
  }
}
</script>

<style lang="less" scoped>
  .item {
    &.qr-code {
      align-items: flex-start;

      .qr-img-outer {
        width: 80px;
        height: 80px;
        border: 1px solid rgba(230, 230, 230, 1);
        border-radius: 4px;
        overflow: hidden;

        .qrimg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .line-text {
      text-align: left;
    }

    .link-bot {
      height: 80px;
      line-height: 41px;
      padding-left: 20px;
      background: #FAFAFA;
      border: 1px solid rgba(230, 230, 230, 1);
      border-radius: 5px;
      margin-right: 20px;
    }
  }
  .img-ele {
    max-width: 200px;
    max-height: 200px;
  }
</style>
