<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-input v-model="query.manageName" clearable placeholder="客户经理名称" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-select
      v-model="query.status"
      clearable
      placeholder="状态"
      class="filter-item"
      style="width: 130px"
    >
      <el-option
        v-for="item in statusOptions"
        :key="item.key"
        :label="item.display_name"
        :value="item.key"
      />
    </el-select>
    <el-input
      v-model="query.id"
      clearable
      placeholder="账号ID"
      style="width: 200px;"
      class="filter-item"
      @keyup.enter.native="toQuery"
    />
    <el-input
      v-model="query.contactInfo"
      clearable
      placeholder="联系人/联系方式"
      style="width: 200px;"
      class="filter-item"
      @keyup.enter.native="toQuery"
    />
    <el-date-picker
      v-model="query.beginTime"
      type="date"
      class="filter-item"
      placeholder="开始日期"
      style="width: 180px;"
    >
    </el-date-picker>
    至
    <el-date-picker
      v-model="query.endTime"
      type="date"
      class="filter-item"
      style="width: 180px;"
      placeholder="结束日期"
    >
    </el-date-picker>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <!-- 新增 -->
  </div>
</template>

<script>
import checkPermission from '@/utils/permission' // 权限判断函数
export default {
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      statusOptions: [
        { key: 0, display_name: '待分配' },
        { key: 1, display_name: '已分配' },
      ],
    }
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$parent.page = 0
      this.$parent.init();
    }
  }
}
</script>
