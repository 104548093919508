<template>
  <div class="app-container">
    <eHeader :query="query"/>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="id" label="账号ID"/>
      <el-table-column prop="phone" label="手机号码"/>
      <el-table-column prop="userName" label="姓名/公司名"/>
      <el-table-column prop="companyPhone" label="联系方式"/>
      <el-table-column prop="email" label="邮箱"/>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <span>{{getStatusText(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="registerTime" label="注册时间">
        <template slot-scope="scope">
          <span>{{parseTime(scope.row.registerTime)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="managerName" label="客户经理">
        <template slot-scope="scope">
          <p style="white-space: pre;">{{scope.row.managerName}}</p>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="220px" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['ADMIN','SrPortalUser_ALL','SrPortalUser_EDIT'])"
            type="primary"
            size="small"
            @click="editHandle(scope.row)"
          >{{getText(scope.row)}}</el-button>
          <el-button
            type="primary"
            size="small"
            @click="lookHandle(scope.row)"
          >查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
    <allocation-manage
      :dialog-flag="allocationFlag"
      :manage-name="currentManageName"
      :client-id="currentClientId"
      @close="allocationFlag = false"
    ></allocation-manage>
    <detail-dialog
      :dialog-flag="detailDialog"
      :form="currentBasieInfo"
      :form-background="currentBackgroundForm"
      @close="detailDialog = false"
    ></detail-dialog>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission';
import initData from '@/mixins/initData';
import { parseTime } from '@/utils/index';
import eHeader from '@/components/alitatech/clientManagement/header';
import allocationManage from '@/components/alitatech/clientManagement/allocationManage';
import detailDialog from '@/components/alitatech/clientManagement/detial';
export default {
  name:'clientManagement',
  components: {
    eHeader,
    detailDialog,
    allocationManage,
  },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      sup_this: this,
      currentData: {
        id: '',
        type: '',
        level: '',
        code: '',
        superior: '',
        phone: '',
        email: '',
        loginEmail: '',
        entryTime: '',
        status: '',
      },
      dialogFlag: false,
      detailDialog: false,
      isAdd: false,
      allocationFlag: false,
      currentManageName: '',
      currentClientId: 0,
      currentBasieInfo:{
        companyName: '',
        companyIntro: '',
        companyWebsite: '',
        companyLisence: '',
        contactAddress: '',
        contactName: '',
        contactPhone: '',
        contactEmail: '',
        contactIdPic: '',
        contactIdPicBack:'',
      },
      currentBackgroundForm:{
        employmentTime: 1,
        coopBrokerUsed:'',
        isATeam: '',
        custAmountMax: '',
        financingVolumeMax: '',
        custAmountNow: '',
        financingVolumeNow: '',
        bussMarketingMode: '',
        commMarketingMode: '',
        custAmountExpect: '',
        financingVolumeExpect: '',
      },
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = 'portal/crm/srPortalUser'
      const sort = 'id,desc'
      this.params = {
        page: this.page,
        size: this.size,
        sort: sort,
      }
      if(this.query.manageName){
        this.params.managerName = this.query.manageName;
      }
      if(this.query.status !== ''){
        this.params.status = this.query.status;
      }
      if(this.query.id){
        this.params.id = this.query.id;
      }
      if(this.query.contactInfo){
        this.params.contactInfo = this.query.contactInfo;
      }
      if(this.query.beginTime){
        this.params.beginTime = this.parseTime(this.query.beginTime);
      }
      if(this.query.endTime){
        this.params.endTime = this.parseTime(this.query.endTime);
      }
      return true
    },
    editHandle(row){
      this.isAdd = false;
      this.currentManageName = row.managerName || '' ;
      this.currentClientId= row.id;
      this.allocationFlag = true;
    },
    lookHandle(row){
      if(row.companyInfo){
        this.currentBasieInfo = row.companyInfo;
      }
      if(row.companyBackground){
        this.currentBackgroundForm = row.companyBackground;
      }
      this.detailDialog = true;
    },
    getText(row){
      return row.managerName ? '转移': '分配';
    },
    getStatusText(row){
      switch (row.status) {
        case 0:
          return '待分配';
        case 1:
          return '已分配';
        case 2:
          return '已成单';
        default:
          return '待分配';
      };
    }
  }
}
</script>

<style scoped>

</style>
